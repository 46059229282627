<template>
  <div>
    <PageTitle
      title="權益政策設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />

    <section v-loading="!loaded" class="section-block">
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="啟用狀態">
          <BaseElSwitch
            v-model="formData.enable"
            inactive-text="關閉"
            active-text="開啟"
            @change="onEnableChange"
          />
        </BaseElFormItem>

        <BaseElFormItem label="權益政策名稱" prop="name">
          <BaseElInput v-model="formData.name" placeholder="請輸入" />
        </BaseElFormItem>
        <BaseElFormItem label="權益政策內容" prop="content">
          <TextEditor :value.sync="formData.content" class="w-[560px]" placeholder="請輸入" />
        </BaseElFormItem>

        <div v-if="formData.enable">
          <BaseElFormItem label="通知「未同意」權益政策的會員">
            <BaseElSwitch
              v-model="formData.informClient"
              inactive-text="關閉"
              active-text="開啟"
            />
          </BaseElFormItem>

          <BaseElFormItem v-if="formData.informClient" label="通知內容" prop="informClientMode">
            <div>
              <BaseElSelect v-model="formData.informClientMode">
                <BaseElSelectOption
                  v-for="option in informClientOptions"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                />
              </BaseElSelect>
              <p class="text-gray-60 text-sm">如舊會員不勾選同意權益政策，則之後每次登入都會跳出通知</p>
            </div>
          </BaseElFormItem>

          <BaseElFormItem v-if="!isFirstConfig && contentChange">
            <div class="flex items-center">
              <BaseElCheckbox v-model="formData.needResetMember">將所有會員的「權益政策同意狀態」變更為「未同意」</BaseElCheckbox>
              <TipInfo :width="300">
                「會員模組 > 會員管理 > 檢視任一會員 > 會員資訊 」有標註會員是否同意權益政策。 <br>
                由於本次變更後的內容可能與先前會員同意的版本不同，建議變更所有會員的同意狀態。
              </TipInfo>
            </div>
          </BaseElFormItem>
        </div>
      </BaseElForm>
    </section>

    <ConfirmModifyMembersPolicyStatusModal
      v-if="modal.modifyMembersPolicy"
      :btn1Loading="loading"
      @close="modal.modifyMembersPolicy = false"
      @confirm="onSubmit"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({name: 'OAuthSetting'})"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, onBeforeMount, ref, onMounted, nextTick, watch } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import TextEditor from '@/components/TextEditor.vue'
import { useBaseForm } from '@/use/useForm'
import TipInfo from '@/components/TipInfo.vue'
import notifyMessage from '@/config/notifyMessage'
import ConfirmModifyMembersPolicyStatusModal from '../components/ConfirmModifyMembersPolicyStatusModal.vue'
import { GetMemberPolicyConfig, UpdateMemberPolicyConfig, ResetMemberPolicyStatus } from '@/api/memberPolicy'
import store from '@/store'
import { get } from 'lodash'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'EditPolicySetting',
  components: {
    PageTitle,
    TextEditor,
    TipInfo,
    ConfirmModifyMembersPolicyStatusModal,
  },
  setup (props) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const router = useRouter()
    const orgId = computed(() => get(store.state, 'org.org.id'))
    const loaded = ref(false)
    const configData = ref({})
    const isFirstConfig = ref(false)
    const contentChange = ref(false)

    initFormData({
      enable: false,
      name: null,
      content: null,
      informClient: false,
      informClientMode: 'always',
      needResetMember: false,
    })

    const informClientOptions = [
      { label: '舊會員登入後跳出通知，需勾選同意權益政策，之後不再跳出通知', value: 'always' },
    ]

    const modal = reactive({
      modifyMembersPolicy: false,
    })

    const formRules = computed(() => {
      return {
        name: [noEmptyRules()],
        content: [noEmptyRules()],
        informClientMode: [noEmptyRules()],
      }
    })

    const submitData = computed(() => {
      return {
        enable: formData.enable,
        name: formData.name,
        content: formData.content,
        informClient: formData.informClient,
        OrgId: orgId.value,
      }
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      console.log('1')
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      console.log('2')

      loading.value = false
      if (formData.needResetMember) {
        modal.modifyMembersPolicy = true
        return
      }

      await onSubmit()
    }

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      const [, err] = await UpdateMemberPolicyConfig(submitData.value)

      if (err) {
        loading.value = false
        window.$message.error(err)
        return
      }

      if (formData.needResetMember) {
        const [, err] = await ResetMemberPolicyStatus({ orgId: orgId.value })
        if (err) {
          loading.value = false
          window.$message.error(err)
          return
        }
        modal.modifyMembersPolicy = false
      }

      loading.value = false
      window.$message.success(notifyMessage.updateSuccess)
      router.push({ name: 'OAuthSetting' })
    }

    const onEnableChange = () => {
      formData.informClient = false
      formData.needResetMember = false
    }

    watch(() => formData.content, () => {
      if (!loaded.value) return
      if (formData.content === configData.value.content) {
        contentChange.value = false
        formData.needResetMember = false
        return
      }
      contentChange.value = true
    })

    onMounted(async () => {
      const [data, err] = await GetMemberPolicyConfig({ orgId: orgId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = data

      formData.enable = data.enable
      formData.name = data.name
      formData.content = data.content
      formData.informClient = data.informClient
      if (!data.id) isFirstConfig.value = true
      await nextTick()
      formUtils.clearValidate(formRef.value)
      loaded.value = true
    })

    return {
      formRef,
      formData,
      formRules,
      onSubmit,
      modal,
      orgId,
      onConfirm,
      configData,
      isFirstConfig,
      informClientOptions,
      loaded,
      contentChange,
      onEnableChange,
      loading,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
